import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HeaderComponent} from './component/header/header.component';
import {FooterComponent} from './component/footer/footer.component';
import {BlogDetailComponent} from './component/blog-detail/blog-detail.component';
import {HomePageComponent} from './component/home-page/home-page.component';
import {AboutMeComponent} from './component/about-me/about-me.component';
import {EventDetailComponent} from './component/event-detail/event-detail.component';
import {ListEventComponent} from './component/list-event/list-event.component';
import {SuppliersPartnerListComponent} from './component/suppliers-partner-list/suppliers-partner-list.component';
import {SuppliersPartnerDetailComponent} from './component/suppliers-partner-detail/suppliers-partner-detail.component';
import {LeaveCommentComponent} from './component/leave-comment/leave-comment.component';
import {BlogListComponent} from './component/blog-list/blog-list.component';
import {DocumentListComponent} from './component/document-list/document-list.component';
import {InterceptorService} from './services/interceptor.service';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {SpinnerComponent} from './share/spinner/spinner.component';
import {FormsModule} from '@angular/forms';
import {ToastComponent} from './share/toast/toast.component';
import {NewsListComponent} from './component/news-list/news-list.component';
import {NewsDetailComponent} from './component/news-detail/news-detail.component';
import {SearchComponent} from "./component/search/search.component";
import {DocumentDetailComponent} from "./component/document-detail/document-detail.component";


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    ListEventComponent,
    BlogListComponent,
    BlogDetailComponent,
    EventDetailComponent,
    AboutMeComponent,
    SuppliersPartnerListComponent,
    SuppliersPartnerDetailComponent,
    LeaveCommentComponent,
    DocumentListComponent,
    ToastComponent,
    NewsListComponent,
    NewsDetailComponent,
    SearchComponent,
    DocumentDetailComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,

    // AnimateOnScrollModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    CarouselModule,
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}],
  bootstrap: [AppComponent],
})
export class AppModule {
}
