<section class="footer-con position-relative">
  <div class="footer_lowerportion position-relative"
       [ngStyle]="{'background-image': 'url(' + webConfig.back_ground_footer_url + ')'}">
    <div class="container">
      <div class="middle_portion" style="font-size: 14px !important;">
        <div class="row">
          <div class="col-xl-3 ">
            <div class="logo-content">
              <a href="/">
                <figure class="footer-logo mb-3"><img class="img-fluid" [src]="webConfig.logo_footer" alt="image"></figure>
              </a>
<!--              <p class="text-size-14 text">{{webConfig.description}}</p>-->
              <ul class="list-unstyled mb-0 social-icons">
                <li>
                  <a [href]="webConfig.community_link" target="_blank" class="text-decoration-none"><i
                    class="fa-brands fa-facebook-f social-networks"></i></a>
                </li>
                <li>
                  <a [href]="webConfig.thread_link" target="_blank" class="text-decoration-none"><i
                    class="fa-brands fa-threads social-networks"></i></a>
                </li>
                <li>
                  <a [href]="webConfig.tiktok_link" target="_blank" class="text-decoration-none">
                    <i class="fa-brands fa-tiktok"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-3 pc">
            <div class="use-link links">
              <h5 class="heading">Trang phổ biến</h5>
              <ul class="list-unstyled mb-0">
                <ng-container *ngFor="let menu of webConfig.menus">
                  <li *ngIf="menu.name">

                    <a [href]="menu.end_point">{{ menu.name }}</a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="col-xl-3 pc">
            <div class="links">
              <h5 class="heading">Về tôi</h5>
              <ul class="list-unstyled mb-0">
                <li><a href="/me">Thông tin</a></li>
                <li><a href="/">Quy định sử dụng</a></li>
              </ul>
            </div>
          </div>
          <div class="col-xl-3 pc">
            <div class="icon">
              <h5 class="heading">Liên Hệ</h5>
              <ul class="list-unstyled mb-0">
                <li class="text">
                  <i class="fa-solid fa-phone"></i>
                  <a [href]="'tel:' + webConfig.phone" class="text-decoration-none">{{webConfig.phone}}</a>
                </li>
                <li class="text">
                  <i class="fa-solid fa-envelope"></i>
                  <a
                    [href]="'mailto:' + webConfig.email"
                    class="text-decoration-none address mb-0">{{webConfig.email}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p class="mb-0">{{webConfig.footer_description}}</p>
    </div>
  </div>
</section>
