import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from './component/home-page/home-page.component';
import {BlogDetailComponent} from './component/blog-detail/blog-detail.component';
import {AboutMeComponent} from './component/about-me/about-me.component';
import {EventDetailComponent} from './component/event-detail/event-detail.component';
import {ListEventComponent} from './component/list-event/list-event.component';
import {SuppliersPartnerListComponent} from './component/suppliers-partner-list/suppliers-partner-list.component';
import {SuppliersPartnerDetailComponent} from './component/suppliers-partner-detail/suppliers-partner-detail.component';
import {BlogListComponent} from './component/blog-list/blog-list.component';
import {DocumentListComponent} from "./component/document-list/document-list.component";
import {NewsListComponent} from './component/news-list/news-list.component';
import {NewsDetailComponent} from './component/news-detail/news-detail.component';
import {SearchComponent} from "./component/search/search.component";
import {DocumentDetailComponent} from "./component/document-detail/document-detail.component";

const routes: Routes = [
  {path: '', component: HomePageComponent},
  {path: 'bai-viet', component: BlogListComponent},
  {path: 'bai-viet/:id', component: BlogDetailComponent},
  {path: 'su-kien', component: ListEventComponent},
  {path: 'su-kien/:id', component: EventDetailComponent},
  {path: 'me', component: AboutMeComponent},
  {path: 'doi-tac', component: SuppliersPartnerListComponent},
  {path: 'doi-tac/:id', component: SuppliersPartnerDetailComponent},
  {path: 'tai-lieu', component: DocumentListComponent},
  {path: 'tai-lieu/:id', component: DocumentDetailComponent},
  {path: 'xu-huong', component: NewsListComponent},
  {path: 'xu-huong/:id', component: NewsDetailComponent},
  {path: 'tim-kiem', component: SearchComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
